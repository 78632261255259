<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>RUBRIK </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateRubrik">
              <CRow>
                <CCol sm="6">
                  <span v-html="url"></span>
                </CCol>
                <CCol sm="6">
                  <CRow>
                    <CCol sm="12">
                    <CInput
                      v-model="title"
                      label="Judul "
                      placeholder="Input Judul "
                    />
                    </CCol>
                    <CCol sm="6">
                      <CTextarea
                        label="Embedded Code Youtube "
                        placeholder="Input Embedded Code Youtube "
                        v-model="url"
                        rows="5"
                      />
                    </CCol>
                    <CCol sm="6">
                      <CTextarea
                        label="Deskripsi Youtube "
                        placeholder="Input Deskripsi Youtube "
                        v-model="link"
                        rows="5"
                      />
                    </CCol>
                    <CCol sm="6">
                      <CInput
                      type="text"
                      v-model="youtube_id"
                      label="Youtube ID"
                      placeholder="Input Youtube ID "
                    />
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                      <label> Status</label>
                      <select v-model="status" placeholder='Pilih Status' class="form-control">
                        <option value="" disabled selected>Pilih Status</option>
                        <option value="1">Aktif</option>
                        <option value="0">Non-Aktif</option>
                      </select>
                      </div>
                    </CCol>       
                  </CRow>  
                </CCol>         
              </CRow>       
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/insidetalk">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data Rubrik gagal diinput.
    </CModal>
   
  </div> 
  
</template>
<style scoped>
span iframe{
  width:100% !important;
}
</style>
<script>
import axios from 'axios';
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      title : "",
      url : "",
      link : "",
      youtube_id : "",
      status : "",
      // fproduct_price : "",
      // qty : "",
      // barcode : "",
      // alert : "",
      // unit : "",
      // product_des : "",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showRubrik();
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updateRubrik: function(){
      // Simple POST request with a JSON body using axios
      const insidetalk = { 
                          title:this.title,
                          url: this.url, 
                          link: this.link, 
                          youtube_id: this.youtube_id, 
                          status: this.status, 
                          id: this.$route.params.id_insidetalk,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"insidetalk/update", insidetalk)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/insidetalk');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showRubrik: function(){

      axios.get(process.env.VUE_APP_BASE_URL+"insidetalk/"+this.$route.params.id_insidetalk)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            // if(response.status==200){
              // this.$router.push('/User');
              this.title=response.data.title;
              this.url=response.data.url;
              this.link=response.data.link;
              this.youtube_id=response.data.youtube_id;
              this.status=response.data.status;
              // this.fproduct_price=response.data.fproduct_price;
              // this.qty=response.data.qty;
              // this.barcode=response.data.barcode;
              // this.alert=response.data.alert;
              // this.unit=response.data.unit;
              // this.product_des=response.data.product_des;

              // alert(response.data);
            // }
            // else{
            //   this.$root.$emit("bv::show::modal", "modalError");
            // }
            
        })
    }
  }
}
</script>